import React, { forwardRef, useEffect, useState } from 'react';
import styles from './products.module.css'; // Import CSS Modules
import ImageModal from '../imagemodal/imagemodal';

import cpuImg from '../../img/icons/cpu.png';
import gpuImg from '../../img/icons/vga.png';
import ramImg from '../../img/icons/ram.png';
import motherboardImg from '../../img/icons/motherboard.png';
import coolerImg from '../../img/icons/cpucooler.png';
import m2ssdImg from '../../img/icons/ssd.png';
import psuImg from '../../img/icons/psu.png';
import fanImg from '../../img/icons/fan.png';
import caseImg from '../../img/icons/case.png';

import newIcon from '../../img/icons/new.png';
import usedIcon from '../../img/icons/used.png';
import warrantyIcon from '../../img/icons/warranty.png';

import haIcon from '../../img/icons/ha.png';
import benchmarkIcon from '../../img/icons/benchmark.png';

import building from '../../img/icons/building.jpg';

import { network } from '../../network/network';

import ModalImageShow from '../../modals/modalimageshow/modalimageshow';

interface Product {
  id: number;
  name: string;
  cpu: string;
  cpuCondition: string;
  cpuWarranty: boolean;
  gpu: string;
  gpuCondition: string;
  gpuWarranty: boolean;
  ram: string;
  ramCondition: string;
  ramWarranty: boolean;
  motherboard: string;
  motherboardCondition: string;
  motherboardWarranty: boolean;
  cooler: string;
  coolerCondition: string;
  coolerWarranty: boolean;
  ssd: string;
  ssdCondition: string;
  ssdWarranty: boolean;
  psu: string;
  psuCondition: string;
  psuWarranty: boolean;
  casefan: string;
  casefanCondition: string;
  casefanWarranty: boolean;
  case: string;
  caseCondition: string;
  caseWarranty: boolean;
  price: string;
  primaryImage: string;
  imagesMultipleString: string[];
  imagesSingleString: string;
  hardverapro: string;
  youtube: string;
  sold: boolean;
}
const Products = forwardRef<HTMLDivElement, any>((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<any>(null);
  const [productList, setProductList] = useState<any[]>([]);

  // Manuálisan megadott termékek listája

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState<string[]>([]);
  const [title, setTitle] = useState<string>('');

  const openImageModal = (images: string[], productName: string) => { // Accept product name
    setCurrentImages(images);
    setTitle(productName); // Set product name as title
    setIsModalOpen(true);
  };

  const closeImageModal = () => {
    setIsModalOpen(false);
    setCurrentImages([]);
  };

  const fetchProducts = async () => {
    setLoading(true);
    network.product.loadProducts()
      .then((response) => {
        const formattedProducts = response.products.map((product: any) => ({
          id: product.id,
          name: product.name,
          cpu: product.cpu,
          cpuCondition: product.cpu_condition,
          cpuWarranty: product.cpu_warranty,
          gpu: product.gpu,
          gpuCondition: product.gpu_condition,
          gpuWarranty: product.gpu_warranty,
          ram: product.ram,
          ramCondition: product.ram_condition,
          ramWarranty: product.ram_warranty,
          motherboard: product.motherboard,
          motherboardCondition: product.motherboard_condition,
          motherboardWarranty: product.motherboard_warranty,
          cooler: product.cooler,
          coolerCondition: product.cooler_condition,
          coolerWarranty: product.cooler_warranty,
          ssd: product.ssd,
          ssdCondition: product.ssd_condition,
          ssdWarranty: product.ssd_warranty,
          psu: product.psu,
          psuCondition: product.psu_condition,
          psuWarranty: product.psu_warranty,
          casefan: product.casefan,
          casefanCondition: product.casefan_condition,
          casefanWarranty: product.casefan_warranty,
          case: product.case,
          caseCondition: product.case_condition,
          caseWarranty: product.case_warranty,
          primaryImage: product.primaryImage,
          imagesMultipleString: product.imagesMultipleString,
          imagesSingleString: product.imagesSingleString,
          hardverapro: product.hardverapro,
          youtube: product.youtube,
          sold: product.sold,
        }));

        const sortedProducts = formattedProducts.sort((a: Product, b: Product) => {
          return Number(a.sold) - Number(b.sold); // a.sold === true esetén 1 lesz, ami a végére kerül
        });
        
        setProductList(sortedProducts);
      })
      .catch((error) => {
        setResult(<ResultError error={`Error loading products: ${error}`} />);
        console.error('Error loading products:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <div className={styles.itemsContainer} ref={ref}>
        {loading && <ResultLoading />}
        {result}
        <div className={styles.productGrid}>
          {productList.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              openImageModal={openImageModal}
            />
          ))}
        </div>
      </div>
      <ModalImageShow title={title} images={currentImages} isOpen={isModalOpen} onClose={closeImageModal} />
    </>
  );
});

interface ProductCardProps {
  product: Product;
  openImageModal: (images: string[], productName: string) => void; // Update to pass product name
}

const ProductCard = ({ product, openImageModal }: ProductCardProps) => {
  const [hovered, setHovered] = useState(false);

  const handleClick = () => {
    if (product.imagesMultipleString.length > 0) {
      openImageModal(product.imagesMultipleString, product.name); // Pass product name
    }
  };

  return (
    <div className={`${styles.productCard} ${product.sold ? styles.sold : ''}`}>
      <div
        className={styles.productCardImage}
        onMouseEnter={() => setHovered(true)} // Set hovered to true on mouse enter
        onMouseLeave={() => setHovered(false)} // Set hovered to false on mouse leave
        onClick={handleClick} // Call handleClick to manage clicks
      >
        <img src={product.primaryImage} alt={product.name} className={styles.productImage} draggable="false" />
        {hovered && product.imagesMultipleString.length > 0 && ( // Only render overlay if hovered and not sold
          <div className={styles.imageOverlay}>
            Több kép megtekintése
          </div>
        )}
      </div>
      <div className={styles.productCardInfo}>
        <h2 className={styles.productCardTitle}>{product.name}</h2>
        {/*{product.sold && <span className={styles.soldBadge}>Elkelt</span>}*/}
        <div className={styles.details}>
            <h4>Specifikáció:</h4>
            <SpecItem title={cpuImg} value={product.cpu} condition={product.cpuCondition} warranty={product.cpuWarranty} />
            <SpecItem title={gpuImg} value={product.gpu} condition={product.gpuCondition} warranty={product.gpuWarranty} />
            <SpecItem title={ramImg} value={product.ram} condition={product.ramCondition} warranty={product.ramWarranty} />
            <SpecItem title={motherboardImg} value={product.motherboard} condition={product.motherboardCondition} warranty={product.motherboardWarranty} />
            <SpecItem title={coolerImg} value={product.cooler} condition={product.coolerCondition} warranty={product.coolerWarranty} />
            <SpecItem title={m2ssdImg} value={product.ssd} condition={product.ssdCondition} warranty={product.ssdWarranty} />
            <SpecItem title={psuImg} value={product.psu} condition={product.psuCondition} warranty={product.psuWarranty} />
            <SpecItem title={fanImg} value={product.casefan} condition={product.casefanCondition} warranty={product.casefanWarranty} />
            <SpecItem title={caseImg} value={product.case} condition={product.caseCondition} warranty={product.caseWarranty} />
        </div>
        <div className={styles.productCardPriceRow}>
          <p className={styles.productCardPrice}>{product.price}</p>
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.btnBenchmark}
            onClick={() => window.open(product.youtube, "_blank")}
            disabled={product.sold}>
            <img src={benchmarkIcon} className={styles.benchmarkIcon} alt="Benchmark" title="Benchmark" draggable="false" />
          </button>
          <button
            className={styles.btnHardverapro}
            onClick={() => window.open(product.hardverapro, "_blank")}
            disabled={product.sold}>
            <img src={haIcon} className={styles.haIcon} alt="Hardverapro" title="Hardverapro" draggable="false" />
          </button>
        </div>
      </div>
    </div>
  );
};

interface SpecItemProps {
  title: string;
  value: string;
  condition: string;
  warranty: boolean;
}

const SpecItem = ({ title, value, condition, warranty }: SpecItemProps) => (
  <div className={styles.inlineItem}>
    <div className={styles.iconAndText}>
      <img src={title} className={styles.inlineIcon} alt={title} title={title} draggable="false" />
      <p>{value}</p>
    </div>
    <div className={styles.smallIcons}>
      <img src={condition === 'new' ? newIcon : usedIcon} className={styles.inlineIconSmall} alt={condition === "new" ? "Új" : "Használt"} title={condition === "new" ? "Új" : "Használt"} draggable="false" />
      {warranty && <img src={warrantyIcon} className={styles.inlineIconSmall} alt="warranty" title="Garancia" draggable="false" />}
    </div>
  </div>
);

const ResultSuccess = ({ message }: { message: string }) => (
  <div className="alert alertsuccess">
    <p>{message}</p>
  </div>
);

// Error message component
const ResultError = ({ error }: { error: string }) => (
  <div className="alert alertdanger">
    <p>{error}</p>
  </div>
);

// Loading spinner component
const ResultLoading = () => (
  <div className="loadingContainer">
    <div className="loadingSpinner"></div>
  </div>
);

export default Products;