import React, { useEffect, useState } from 'react';
import styles from './availableproducts.module.css';

import txytext from '../../img/txytext.png';

import cpuImg from '../../img/icons/cpu.png';
import gpuImg from '../../img/icons/vga.png';
import ramImg from '../../img/icons/ram.png';
import motherboardImg from '../../img/icons/motherboard.png';
import coolerImg from '../../img/icons/cpucooler.png';
import m2ssdImg from '../../img/icons/ssd.png';
import psuImg from '../../img/icons/psu.png';
import fanImg from '../../img/icons/fan.png';
import caseImg from '../../img/icons/case.png';

import { network } from '../../network/network'; // Assuming network module exists

const AvailableProducts = () => {
    const [productList, setProductList] = useState<any[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState<React.ReactNode | null>(null);

    const fetchProducts = async () => {
        setLoading(true);
        network.product.loadProducts()
            .then((response) => {
                const formattedProducts = response.products
                    .filter((product: any) => product.imagesSingleString && !product.sold)
                    .map((product: any) => ({
                        id: product.id,
                        name: product.name,
                        cpu: product.cpu,
                        cpuCondition: product.cpu_condition,
                        cpuWarranty: product.cpu_warranty,
                        gpu: product.gpu,
                        gpuCondition: product.gpu_condition,
                        gpuWarranty: product.gpu_warranty,
                        ram: product.ram,
                        ramCondition: product.ram_condition,
                        ramWarranty: product.ram_warranty,
                        motherboard: product.motherboard,
                        motherboardCondition: product.motherboard_condition,
                        motherboardWarranty: product.motherboard_warranty,
                        cooler: product.cooler,
                        coolerCondition: product.cooler_condition,
                        coolerWarranty: product.cooler_warranty,
                        ssd: product.ssd,
                        ssdCondition: product.ssd_condition,
                        ssdWarranty: product.ssd_warranty,
                        psu: product.psu,
                        psuCondition: product.psu_condition,
                        psuWarranty: product.psu_warranty,
                        casefan: product.casefan,
                        casefanCondition: product.casefan_condition,
                        casefanWarranty: product.casefan_warranty,
                        case: product.case,
                        caseCondition: product.case_condition,
                        caseWarranty: product.case_warranty,
                        primaryImage: product.primaryImage,
                        imagesMultipleString: product.imagesMultipleString,
                        imagesSingleString: product.imagesSingleString,
                        hardverapro: product.hardverapro,
                        youtube: product.youtube,
                        sold: product.sold,
                    }));
                setProductList(formattedProducts);
            })
            .catch((error) => {
                setResult(<ResultError error={`Error loading products: ${error}`} />);
                console.error('Error loading products:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    const handlePrev = () => {
        setFade(styles.fadeOut);
        setTimeout(() => {
            setCurrentIndex(prevIndex => (prevIndex === 0 ? productList.length - 1 : prevIndex - 1));
            setFade(styles.fadeIn);
        }, 500);
    };

    const handleNext = () => {
        setFade(styles.fadeOut);
        setTimeout(() => {
            setCurrentIndex(prevIndex => (prevIndex === productList.length - 1 ? 0 : prevIndex + 1));
            setFade(styles.fadeIn);
        }, 500);
    };

    if (loading) {
        return <div className={styles.loading}>Loading...</div>; // Placeholder for loading state
    }

    if (result) {
        return <div>{result}</div>; // Placeholder for error state
    }

    const currentProduct = productList[currentIndex];

    return (
        <>
            {productList.length > 0 ? (
                <div className={`${styles.card} ${fade}`}>
                    <div className={styles.left} style={{ backgroundColor: currentProduct.bgcolor }}>
                        <div className={styles.slider} onClick={handlePrev}>
                            <i className="material-icons">arrow_back</i>
                        </div>
                        <img src={txytext} className={styles.wordmark} alt="Toxy" title="Toxy" draggable="false" />
                    </div>
                    <div className={styles.right}>
                        <img src={currentProduct.imagesSingleString} className={styles.helmet} alt="PC" title="PC" draggable="false" />
                        <div className={styles.productInfo}>
                            <h3>Elérhető számítógépek</h3>
                            <h1>{currentProduct.name}</h1>
                            <div className={styles.details}>
                                <h4>Specifikáció</h4>
                                {[
                                    { img: cpuImg, text: currentProduct.cpu },
                                    { img: gpuImg, text: currentProduct.gpu },
                                    { img: ramImg, text: currentProduct.ram },
                                    { img: motherboardImg, text: currentProduct.motherboard },
                                    { img: coolerImg, text: currentProduct.cooler },
                                    { img: m2ssdImg, text: currentProduct.ssd },
                                    { img: psuImg, text: currentProduct.psu },
                                    { img: fanImg, text: currentProduct.casefan },
                                    { img: caseImg, text: currentProduct.case },
                                ].map(({ img, text }, index) => (
                                    <div key={index} className={styles.inlineItem}>
                                        <img src={img} className={styles.inlineIcon} alt={text} title={text} draggable="false" />
                                        <p>{text}</p>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.slider} onClick={handleNext}>
                                <i className="material-icons">arrow_forward</i>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.card}>
                    <ResultError error="Nincs elérhető termék" />
                </div>
            )}
        </>
    );
};

// Error message component
const ResultError = ({ error }: { error: string }) => (
    <div className="alert alertdanger">
        <p>{error}</p>
    </div>
);

// Loading spinner component
const ResultLoading = () => (
    <div className="loadingContainer">
        <div className="loadingSpinner"></div>
    </div>
);

export default AvailableProducts;
