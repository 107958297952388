import React from 'react';
import styles from './info.module.css';

import newIcon from '../../img/icons/new.png';
import usedIcon from '../../img/icons/used.png';
import warrrantyIcon from '../../img/icons/warranty.png';

export const Info = () => {
  return (
    <div className={styles.infoContainer}>
      <h1>Információk</h1>
      <h2>Fontos tudnivalók a számítógépekről</h2>
      <ul>
        <li>A számítógépek új és használt alkatrészekből állnak össze, amelyeket én magam tesztelek.</li>
        <li>Az ikonok segítségével könnyen azonosítható, hogy mely alkatrészek újak, használtak, illetve rendelkeznek-e garanciával.
          <img src={newIcon} style={{ width: '22px' }} alt="new" title="Új" />
          <img src={usedIcon} style={{ width: '22px' }} alt="used" title="Használt" />
          <img src={warrrantyIcon} style={{ width: '22px' }} alt="warranty" title="Garancia" />
        </li>
        <li>Ez az oldal nem webshopként működik, hanem egy platformként szolgál, ahol az általam elkészített számítógépek megtekinthetők.</li>
        <li>A gépeket nem tartogatom napokig, nem várok, ígéretből sok van a világon. Az viszi, aki a leggyorsabb.</li>
        <li>Az oldalon semmilyen adatgyűjtés nem történik.</li>
        <li>A kapcsolatfelvétel jelenleg <a href="https://hardverapro.hu/tag/toxy_2.html">HardverAprón</a> keresztül történik.</li>
      </ul>
      <h2>Pár szó rólam</h2>
      <ul>
        <li>Huszonéves mérnökinformatikus vagyok, szenvedélyem a számítógépek világa.</li>
        <li>Minden egyes számítógépet én építek és tesztelek, és saját magam készítem el a benchmark videókat is, amelyekben bemutatom, hogyan teljesítenek különböző játékok alatt.</li>
        <li>Személyes megtekintés Debrecenben lehetséges, előzetes egyeztetés alapján.</li>
      </ul>
    </div>
  );
};
