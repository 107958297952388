import React, { ReactNode, useEffect, useState } from 'react';
import styles from './modal.module.css';

interface ButtonProps {
  label: string;
  onClick: (e: React.FormEvent<Element>) => void | Promise<void>;
  disabled?: boolean;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  footerButtons?: ButtonProps[] | null;
  loading?: boolean;
  resultMessage?: ReactNode;
  style?: React.CSSProperties;
}

const Modal = ({ isOpen, onClose, title, children, footerButtons, loading, resultMessage, style }: ModalProps) => {
  const [localResultMessage, setLocalResultMessage] = useState<ReactNode | null>(null);

  useEffect(() => {
    if (isOpen) {
      setLocalResultMessage(resultMessage);
    }
  }, [isOpen, resultMessage]);

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget === e.target) {
      onClose();
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContent} style={style}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{title}</h2>
          <span className={styles.closeBtn} onClick={onClose}>&times;</span>
        </div>
        <div className={styles.modalBody}>
          {children}
        </div>
        <div className={styles.modalFooter}>
          <div className={styles.resultContainer}>
            {localResultMessage}
          </div>
          <div className={styles.footerActions}>
            {loading && <ResultLoading />}
            {footerButtons && footerButtons.map((button, index) => (
              <button
                key={index}
                className={styles.footerButton}
                onClick={button.onClick}
                disabled={button.disabled || loading}
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ResultLoading = () => (
  <div className="loadingContainer">
    <div className="loadingSpinner"></div>
  </div>
);

export default Modal;
