// httpclient.ts
import { baseUrl } from '../baseurl';

interface PostJsonOptions {
    action: string;
    [key: string]: any;
}

interface PostFormDataOptions {
    action: string;
    data: FormData;
}

const postJson = async ({ action, ...data }: PostJsonOptions) => {
    try {
        const response = await fetch(`${baseUrl}manage.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                action,
                ...data,
            }),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error in response:', errorText);
            throw new Error('Error in JSON submission');
        }

        return response.json();
    } catch (error) {
        console.error('Network error or JSON parsing error:', error);
        throw error;
    }
};

const uploadFormData = async ({ action, data }: PostFormDataOptions) => {
    data.append('action', action);
    try {
        const response = await fetch(`${baseUrl}manage.php`, {
            method: 'POST',
            body: data,
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error in response:', errorText);
            throw new Error('Error submitting product');
        }

        return response;
    } catch (error) {
        console.error('Network error or JSON parsing error:', error);
        throw error;
    }
};

export const httpClient = {
    postJson,
    uploadFormData,
};
