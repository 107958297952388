import React, { useState, useEffect } from 'react';
import Modal from '../modal/modal';
import styles from './modalimageshow.module.css';

interface ModalImageShowProps {
    title: string;
    images: string[];
    isOpen: boolean;
    onClose: () => void;
}

const ModalImageShow = ({ title, images, isOpen, onClose }: ModalImageShowProps) => {

    const handleCancel = () => {
        onClose();
    };

    const footerButtons = [
        {
            label: 'Bezár',
            onClick: handleCancel,
            disabled: false,
        },
    ];

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            loading={false}
            resultMessage={null}
            style={{ width: '600px' }}
            footerButtons={footerButtons}
        >
            <div className={styles.imageGallery}>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Product Image ${index + 1}`}
                        className={styles.modalImage}
                    />
                ))}
            </div>
        </Modal>
    );
};

export default ModalImageShow;